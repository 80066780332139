$root: quote(loader); // #{$root}
.#{$root}-container{
    position:fixed;
    @include size(100%);
    //background:#fff;
    z-index:9999;
    left:0%;
    top:0%;
    display:none;
    body.is-loading &{
        display:block;
    }
    .#{$root}-shutter{
        position:absolute;
        width:100%;
        height:100%;
        top:0%; 
        left:0;
        z-index:0;
        svg{
            fill:#fff; 
            height:100%; 
            width:100%;
        } 
    }
    .#{$root}{
        position:relative;
        @include size(100%);
        display:flex;
        align-items:center;
        justify-content:center;
        z-index:1;
        .logo{
            z-index:2;
            @include size(60px, 40px);
            position:absolute;
            opacity:0;
            //margin-bottom:2em;
            svg{
                @include size(100%);
                fill-rule:evenodd;
                .subname{ 
                    opacity:0;
                }
                .name{
                    fill:$color-secondary;
                }
            }
        }
        /*
        .logo-outline{
            position:relative;
            z-index:1;
            padding-bottom:5%;
            svg{
                stroke-width:1px;
                stroke:$gray-200;
                stroke-miterlimit:10;
                fill:none;
                @include bp(width,(md:270px,lg:290px,xl:320px,xxl:360px));
                path{
                    vector-effect: non-scaling-stroke;
                }
            }
        }*/
        .circular{
            @include animation(rotate, 1s ,ease(in-out-quad) ,infinite);
            @include transform-origin(center center);
            @include size(250px);
            .loader-path {
                stroke-dasharray:150,200;
                stroke-dashoffset:0;
                stroke:darken($light,3%);
                @include animation(dash, 1.5s, ease(in-out-quad), infinite);
                //@include animation(color, 2s, ease(in-out-quad), infinite);
                //@include transition(all .8s ease(in-out-quad));
                stroke-linecap:round;
            }
        }
        @-webkit-keyframes rotate {
            100% {
                @include rotate(360deg);
            }
        }
        @keyframes rotate {
            100% {
                @include rotate(360deg);
            }
        }
        @-webkit-keyframes dash {
            0% {
            stroke-dasharray: 1,200;
            stroke-dashoffset: 0;
            }
            50% {
            stroke-dasharray: 89,200;
            stroke-dashoffset: -35;
            }
            100% {
            stroke-dasharray: 89,200;
            stroke-dashoffset: -124;
            }
        }
        @keyframes dash {
            0% {
            stroke-dasharray: 1,200;
            stroke-dashoffset: 0;
            }
            50% {
            stroke-dasharray: 89,200;
            stroke-dashoffset: -35;
            }
            100% {
            stroke-dasharray: 89,200;
            stroke-dashoffset: -124;
            }
        }
        @-webkit-keyframes color {
            0% {stroke: $color-primary;}
            30% {stroke: $color-secondary;}
            70%,90% {stroke: $color-tertiary;}
        }
        @keyframes color {
            0% {stroke: $color-primary;}
            30% {stroke: $color-secondary;}
            70%,90% {stroke: $color-tertiary;}
        }
    }
}