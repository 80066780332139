$root: quote(content); // #{$root}
.#{$root}-container{   
    overflow:hidden;
    h1{   
        @include bp(font-size,(  
            md:$h1-font-size*.92,
            lg:$h1-font-size,
            xl:$h1-font-size*1.08,   
            xxl:$h1-font-size*1.16   
        )); 
    } 
    p{ 
        color:$gray-700;
    } 
    p.lead{ 
        font-family:$secondary-font-family;
        font-weight:600;
        color:$gray-800;
        //line-height:1.75;
    }
    .content-area{
        
    }
    @include media-breakpoint-down(lg) {
        text-align:center;
        .area-element-image{
             margin:1rem 0 2rem;   
        }
        .col-index-1.col-set-6-6{
            .area-element-image{
                display:none;
            }
        }
    }
}
  
.#{$root}-wrapper{
    @include bp(padding-top,(xs:5rem,sm:3rem,md:4rem,lg:4.8rem,xl:5.4rem,xxl:6rem));
    @include bp(padding-bottom,(xs:2rem,sm:3rem,md:4rem,lg:4.8rem,xl:5.4rem,xxl:6rem));  
    img{
        @include img-fluid(); 
    }
    &.content-page{ // dla akcji page
        padding-bottom:0; 
    }
} 

.#{$root}-default{
 
}  

.#{$root}-area{



}

