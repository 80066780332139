.content-area{
    @include media-breakpoint-down(lg) {
        text-align:center;
        .area-element-image{
             margin:1rem 0 2rem;   
        }
        .col-index-1.col-set-6-6{
            .area-element-image{
                display:none;
            }
        }
        .area-element-header{
            h2{
                margin-bottom:1rem;
            }
        }
    }
}

.contact-data-section{
    h4{
        margin-bottom:1.2rem;
    }
    p{
        
        font-size:.825rem;
        > a{
            color:$gray-700;
        }
    }
}

.btn-section{
    border-top:1px solid darken($light,5%);
    .area-element-link{
        text-align:center;
        padding:3em 0;
        a{
            font-weight:600;
            background-repeat: no-repeat;
            background-size:30px 15px;
            background-position:1em 1.2em;
            padding:1em 2em 1em 4.2em;
            white-space:nowrap;
            display:inline-block;
            font-size:1rem;
            text-decoration:none;
            background-image: url('data:image/svg+xml, %3Csvg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="15" viewBox="0 0 40 20" xml:space="preserve"%3E%3Cg style="stroke:%23C32385;stroke-width:2px"%3E%3Cline x1="0" y1="10" x2="40" y2="10"%3E%3C/line%3E%3Cline x1="8" y1="0" x2="0" y2="10"%3E%3C/line%3E%3Cline x1="8" y1="20" x2="0" y2="10"%3E%3C/line%3E%3C/g%3E%3C/svg%3E ');
        }
    }
}

.client-section{
    .area-element-image{
        @include bp(padding,(  
            md:.5em .9rem,
            lg:.5em 1.1rem,
            xl:.5em 1.3rem,
            xxl:.5em 1.5rem  
        )); 
        img{
            @include img-fluid();
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity:.6;
            @include transition(all .4s ease(in-out-quad));
        }
        &:hover{
            img{
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
                opacity:1;
            }
        }
    }
    p{
        color:$gray-500;
    }
    @include media-breakpoint-down(lg) {
        .col-element-image{
            width:49%;
        }
    } 
}

.area-element-header{ 
    h2{
        margin-bottom:2rem;
        color:$color-tertiary;
    }
    @include media-breakpoint-down(lg) {
        text-align:center;
    }
}

.team-section{
    .area-element-header{
        h2{
            margin-bottom:2rem;
            color:$color-tertiary;
        }
    }
    .area-element-image{
        @include bp(padding,(  
            md:.4rem,
            lg:.6rem,
            xl:.8rem,
            xxl:1rem  
        )); 
        .figure{
            overflow:hidden;
            border-radius:50%;
            border:2px solid rgba($dark,.4);
        }
    }
    .area-element-wysiwyg{
        h5{
            font-weight:600;
            word-spacing:760px;
        }
        p{
            color:$gray-600;
        }
    }
    @include media-breakpoint-down(lg) {
        .area-element-image{
            .figure{
                max-width:160px;  
                margin:auto;
            }
        }
        .area-element-wysiwyg{
            h5{
                word-spacing:inherit;
            }   
        }
    }
}

.img-txt-section{
    svg.icon{
        display:block;
        fill:$color-tertiary;
        @include size(46px);
    }
    h2{
        color:$color-tertiary;
        @include bp(font-size,(  
            md:$h3-font-size*.92,
            lg:$h3-font-size,
            xl:$h3-font-size*1.08,   
            xxl:$h3-font-size*1.16   
        )); 
    }
    p.lead{
        @include media-breakpoint-up(lg) {
            //width:80%;
        }
        font-weight:600;
        color:$gray-800;
    }
    p{
        font-weight:400;
        color:$gray-600;
    }
    @include media-breakpoint-down(lg) {
        text-align:center; 
        svg.icon{
            margin:0 auto 1rem;   
        }
    }
}