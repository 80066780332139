
/* 

====== Zoom effect ======

*/
.mfp-zoom-in {
  
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out; 
    transform: scale(0.8); 
  }
  
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }
  
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1); 
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  
  /* animate out */
  &.mfp-removing {
    
    .mfp-with-anim {
      transform: scale(0.8); 
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
    
  }
  
}


/* 

====== Newspaper effect ======

*/
.mfp-newspaper {
  
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out; 
    transition: all 0.5s;
    
    transform: scale(0) rotate(500deg);
  }
  
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.5s;
  }
  
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  
  /* animate out */
  &.mfp-removing {
    
    .mfp-with-anim {
      transform: scale(0) rotate(500deg);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
    
  }
  
}



/* 

====== Move-horizontal effect ======

*/
.mfp-move-horizontal {
  
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;
    
    transform: translateX(-250px);
  }
  
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s;
  }
  
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateX(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  
  /* animate out */
  &.mfp-removing {
    
    .mfp-with-anim {
      transform: translateX(250px);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
    
  }
  
}


/* 

====== 3d unfold ======

*/
.mfp-3d-unfold {
  
 
  .mfp-content {
    perspective: 900px; 
  }
  
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.7s cubic-bezier(0.770, 0.000, 0.175, 1.000);
    transform-style: preserve-3d;
    transform: scale(1.3) rotateX(-40deg);
  }
  
  
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.5s;
  }
  
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1) rotateX(0deg);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  
  /* animate out */
  &.mfp-removing {
    
    .mfp-with-anim {
      transform:scale(.3)  rotateX(20deg);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
    
  }
  
}




/* 

====== Zoom-out effect ======

*/
.mfp-zoom-out {
  
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out; 
    transform: scale(1.3); 
  }
  
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }
  
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1); 
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  
  /* animate out */
  &.mfp-removing {
    
    .mfp-with-anim {
      transform: scale(1.3); 
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
    
  }
  
}
