@mixin button-shared-props() {
	display:inline-block;
	font-weight:$btn-font-weight;
	white-space:nowrap;
	vertical-align:middle;
	user-select:none;
	text-decoration:none;
	position:relative;
	overflow:hidden;
	//line-height:1.25 !important;
	z-index:1;
	@include transition(all .35s ease(in-out-sine));
	&:before{
		content:'';
		position:absolute;
		z-index:-1;
		@include transition(all .35s ease(in-out-sine));
	}
	&:focus,
	&.focus{
		outline: 0;
		box-shadow: none !important;//$btn-focus-box-shadow;
	}
	&:hover,
	&.hover{
		text-decoration:none;
	}
}

@mixin button-size-set($isExtended:false) {
	@include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
	@if $isExtended{  // reset ustawien powyzej
		padding:0 !important;
		border:0 !important;
	}
	@if $isExtended{
		> span,
		> span:before{
			@include button-size($btn-padding-y, $btn-padding-x, inherit, $btn-line-height, $btn-border-radius);
		}
	}
	&-lg {
	  	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
		@if $isExtended{
			> span,
			> span:before{
				@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
			}
		}
	}
	&-sm {
	 	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
		@if $isExtended{
			> span,
			> span:before{
				@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
			}
		}
	}
}

@mixin button-swipe-variant($color) {
	color:$color;
	background-color:transparent;
	background-image:none;
	border-color:$color;
	&:before{
		background-color:$color;
		color:color-yiq($color); 
	}
	&:hover {
		color:color-yiq($color); 
		border-color:$color;
	}
}
@mixin button-swipe-invert-variant($color) {
	color:color-yiq($color);
	background-color:transparent;
	background-image:none;
	border-color:$color;
	&:before{
		background-color:$color;
		color:color-yiq($color); 
	}
	&:hover {
        color:$color;
		border-color:$color;
	}
}

@mixin button-rotoswipe-variant($color) {
	@include button-swipe-variant($color);
}

@mixin button-roto3d-variant($color) {
	background-color:transparent;
	> span{
		background-color:$color;
		color:color-yiq($color);
		&:before{
			background-color:darken($color,10%);
			color:color-yiq($color); 
		}
	}
	&:hover {
		color:color-yiq($color); 
		border-color:$color;
	}
}