 $root: quote(breadcrumbs); // #{$root}
.#{$root}-wrapper{ 
    position:relative;
    a.home{
        position:absolute;
        bottom:-3.7em;
        padding:1em;
        display:block;
        left:18px;
        z-index:210;
        width:26px;
    }
	ol.breadcrumb{
		padding:1em;
		//@media (min-width: @screen-md){
			padding:1em 1em 1em 3em;
			position:absolute;
			left:$grid-gutter-width/2;
			bottom:-5em;		
			max-width:940px;	 
		//}
		//.opacity(.6);
		margin:0;
		z-index:13;
		font-size:.82em;
		background:transparent;
        background-repeat:no-repeat; 
        background-position:0% 14px;
        background-size:16px; 
        background-image:url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMwNi43NzMgMzA2Ljc3MyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzA2Ljc3MyAzMDYuNzczOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CjxnPgoJPHBhdGggZD0iTTMwMi45MywxNDkuNzk0YzUuNTYxLTYuMTE2LDUuMDI0LTE1LjQ5LTEuMTk5LTIwLjkzMkwxNjQuNjMsOC44OTggICBjLTYuMjIzLTUuNDQyLTE2LjItNS4zMjgtMjIuMjkyLDAuMjU3TDQuNzcxLDEzNS4yNThjLTYuMDkyLDUuNTg1LTYuMzkxLDE0Ljk0Ny0wLjY2MiwyMC45MDJsMy40NDksMy41OTIgICBjNS43MjIsNS45NTUsMTQuOTcxLDYuNjY1LDIwLjY0NSwxLjU4MWwxMC4yODEtOS4yMDd2MTM0Ljc5MmMwLDguMjcsNi43MDEsMTQuOTY1LDE0Ljk2NSwxNC45NjVoNTMuNjI0ICAgYzguMjY0LDAsMTQuOTY1LTYuNjk1LDE0Ljk2NS0xNC45NjV2LTk0LjNoNjguMzk4djk0LjNjLTAuMTE5LDguMjY0LDUuNzk0LDE0Ljk1OSwxNC4wNTgsMTQuOTU5aDU2LjgyOCAgIGM4LjI2NCwwLDE0Ljk2NS02LjY5NSwxNC45NjUtMTQuOTY1VjE1NC4wMjRjMCwwLDIuODQsMi40ODgsNi4zNDMsNS41NjdjMy40OTcsMy4wNzMsMTAuODQyLDAuNjA5LDE2LjQwMy01LjUxM0wzMDIuOTMsMTQ5Ljc5NHoiIGZpbGw9IiNkNGQ0ZDQiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
		li{ 
			color:rgba($dark,.4);
			font-weight:300;
            &:before{
                color:rgba($dark,.2);
            }
			a{
				text-decoration:none;
				padding:0;
				position:relative;
				color:rgba($dark,.8);
				&:before{
					content:'';
					position:absolute;
					left:0;
					bottom:-1px;
					width:0;
					height:1px;
					border-top:1px solid rgba($dark,.3);
					@include transition(all .4s ease(in-out-quad));
				}
				&:hover{
					&:before{
						width:98%;
					}
				}
			}
            //overflow:hidden;
            //text-overflow: ellipsis;
            //max-width:100px;
            &.home{
                
            }
		}
		&:hover{
			//.opacity(1);
		}
	}
}