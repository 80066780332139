$element: quote(area-element-icon);
.#{$element}{
    $icon-sizes: (
        xs: 24px,
        sm: 36px,
        md: 54px,
        lg: 82px,
        xl: 120px,
        xxl: 160px
    ) !default;
    @each $breakpoint, $size in $icon-sizes {
        .icon.icon-#{$breakpoint}{
            @include size($size);
        }
    }
    $icon-theme-colors: (
      "primary": $primary,
      "secondary": $secondary,
      "tertiary": $tertiary,
      "light": $light,
      "dark": $dark
    );
    @each $theme, $color in $icon-theme-colors {
        .icon.icon-#{$theme}{
            fill:$color;
        }
    }
}

