$root: quote(top); // #{$root}
.#{$root}-wrapper{
    overflow:hidden;
    .#{$root}-inner{
        display:flex;
        flex-wrap:wrap;
		align-items:center;
        overflow:hidden;
        min-height:87vh;
        position:relative;
        @include bp(height,(
            xs:720px,
            sm:560px,
            md:680px,
            lg:660px,
            xl:650px,
            xxl:640px 
        ));
        body.page-inner &{
            min-height:50vh;
            @include bp(height,(
                xs:520px,
                sm:360px,
                md:480px,
                lg:460px,
                xl:450px,
                xxl:440px
            ));
        } 
        .img{
            @include size(100%);
            position:relative;
            .bg-img,
            .front-img{
                position:absolute;
                top:0;
                left:0;
                @include size(100%);
                .inner{
                    position:relative;
                    top:-6%;
                    left:-6%;
                    @include size(112%);
                    img{
                        object-fit:cover;
                        object-position:50% 15%;
                        font-family:'object-fit: cover; object-position:50% 15%;';
                        @include size(100%);
                    }
                }
            }
            .bg-img{
                z-index:1;
                .inner{

                }
            }
            .front-img{
                z-index:3;
                .inner{

                }
            }
            .accent-logo{
                position:absolute;
                @include size(100%,100%); 
                z-index:2;
                top:0%;
                left:0;                
                .inner{
                    display:flex;
                    align-items:center;
                    justify-content:center; 
                    @include size(100%,100%);

                    svg{
                        width:740px;
                        height:622px;
                        .line{
                            stroke:rgba(#fff,.2);
                            stroke-width:.3;
                        }
                    }
                }



            }
        }
        .caption{
            position:absolute;
            z-index:9;
            @include size(100%);
            top:0;
            left:0;
            display:flex;
            align-items: center;
            justify-content: center;
            text-align:center;
            color:$light;
            //@include bp(height,(md:600px,lg:580px,xl:600px,xxl:640px));
            //@include bp(width,(md:600px,lg:580px,xl:600px,xxl:620px));
            &-inner{
                pointer-events:auto;
                @include media-breakpoint-down(lg) {
                    padding:2rem 2rem 0;
                }
                h4{
                    font-weight:300;
                    text-transform:uppercase;
                    @include bp(font-size,(xs:1.0rem,sm:1.2rem,md:1.25rem,lg:1.3rem,xl:1.35rem,xxl:1.4rem));
                }
                h1{
                    font-family:$secondary-font-family;

                    font-weight:700;  
                    color:$white;
                    @include bp(font-size,(xs:2.4rem,sm:2.6rem,md:2.9rem,lg:3.2rem,xl:3.4rem,xxl:3.6rem));
                    > span{
                        display:block;
                    }
                    body.page-inner &{
                        @include bp(font-size,(xs:2.1rem,sm:2.3rem,md:2.45rem,lg:2.6rem,xl:2.85rem,xxl:3.0rem));
                    }
                }
                p{
                    width:90%;
                    @include media-breakpoint-up(lg) {
                        width:85%;
                        max-width:560px;
                    }
                    margin:auto;
                    color:rgba($light,.8);
                    //font-size:1.15rem;
                    @include py(.5rem);
                } 
                a{
                    display:inline-block;
                    margin-top:$spacer;
                }
            }
        }
        .btn-scroll{
            position:absolute;
            bottom:4rem;
            left:50%;
            z-index:11;
            margin-left:-21px;
            @include size(42px);
            opacity:.6;
            @include transition(all .3s ease(in-out-quad));
            svg{
                fill:none;
                stroke-width:1;
                stroke:$light;
                stroke-linecap:round;
                stroke-miterlimit:10;
            }
            &:hover{
                opacity:1;
            }
        }
    }
}
