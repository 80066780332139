@mixin keyframes($name) {
	@-webkit-keyframes #{$name}{
		@content; 
	}
	@-moz-keyframes #{$name}{
		@content;
	}
	@-ms-keyframes #{$name}{
		@content;
	}
	@keyframes #{$name}{
		@content;
	} 
}

// Usage: @include animation(block-1-animate, $speed, ease-out, infinite)
@mixin animation ($animation, $duration, $transition, $iteration, $direction:normal){
	-webkit-animation-name: $animation;
	-webkit-animation-duration: $duration;
	-webkit-animation-timing-function: $transition;
	-webkit-animation-iteration-count: $iteration;
    -webkit-animation-direction: $direction;
	-moz-animation-name: $animation;
	-moz-animation-duration: $duration;
	-moz-animation-timing-function: $transition;
	-moz-animation-iteration-count: $iteration;
    -moz-animation-direction: $direction;
	-o-animation-name: $animation;
	-o-animation-duration: $duration;
	-o-animation-timing-function: $transition;
	-o-animation-iteration-count: $iteration;
    -o-animation-direction: $direction;
	animation-name: $animation;
	animation-duration: $duration;
	animation-timing-function: $transition;
	animation-iteration-count: $iteration;
    animation-direction: $direction;
}

@mixin multiple_animations($list) {
	$combined: '';

	@for $i from 1 through length($list) {
		$anim: nth($list, $i);

		@if $i == length($list) {
			$combined: $combined + $anim;
		}
		@else {
			$combined: $combined + $anim + ',';
		}
	}

	animation: unquote($combined);
}