@import "../bootstrap4/mixins";

@import "mixins/advisage_transition";
@import "mixins/advisage_transform";
@import "mixins/advisage_buttons";
@import "mixins/advisage_ease";
@import "mixins/advisage_animation";

@mixin coverer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


// @include bp(width,(md:100px,lg:150px,xl:300px));
@mixin bp($prop, $bp-val){
    @each $bp, $val in $bp-val {
        @include media-breakpoint-up(#{$bp}) {
            #{$prop}: $val;
        }
    }
}


@mixin user-select($v){
    -webkit-user-select: $v;
    -moz-user-select: $v;
    -ms-user-select: $v;
    user-select: $v;
}

// padding top bottom
@mixin py($v){
    padding-top:$v;
    padding-bottom:$v;
}
@mixin padding-top-bottom($v){
    @include py($v);
}

// padding left right
@mixin px($v){
    padding-left:$v;
    padding-right:$v;
}
@mixin padding-left-right($v){
    @include px($v);
}