 $root: quote(content-area-brick); // #{$root}
.#{$root}{  
    position:relative;

    // obrazy tla
    &.background-image-standard,
    &.background-image-fixed,
    &.background-image-parallax{
        .#{$root}-bg{
            > img{
                @include img-fluid();
                width:100%;
            }
            z-index:0;
            body.editmode &{
                position:absolute;
                height: 100%;
                width: 100%;
            }
            body.editmode &{
                .pimcore_tag_image{
                    height: 100% !important;
                    width: 100% !important;
                    img{
                        height: 100% !important;
                        width: 100% !important;
                        object-fit: cover !important;
                        min-height:300px !important;
                    }
                }
            }
        }
        .#{$root}-inner{
            @include media-breakpoint-up(lg) {
                position:absolute;
                top:0;
                left:0;
                @include size(100%);
                z-index:1;
                display:flex;
                align-items:center;
            }
            body.editmode &{
                position:static;
                .pimcore_tag_wysiwyg .pimcore_wysiwyg{
                    //min-height:32px !important;
                }
            }
        }
    }
    &.background-image-standard{

    }
    &.background-image-fixed{

    }
    &.background-image-parallax{

    }
    &.background-light{
        background-color:$light;
    }
    &.background-dark{
        background-color:$dark;
    }

    // motyw kolorystyczny
    &.color-light{
        h2,
        h3,
        h4,
        h5,
        h6,
        .pimcore_tag_input{
            color:$light;
        }
        p,
        li,
        span,
        .pimcore_tag_wysiwyg{
            color:rgba($light,.8);
        }
    }

    // wyrownanie poziom
    &.align-h-center{
        .#{$root}-inner{
            .area-element,
            .area-block{
                text-align:center;
            }
        }
    }
    &.align-h-left{
        .#{$root}-inner{
            .area-element,
            .area-block{
                text-align:left;
            }
        }
    }
    &.align-h-right{
        .#{$root}-inner{
            .area-element,
            .area-block{
                text-align:right;  
            }
        }
    } 
    &.align-h-default{
        .#{$root}-inner{
            .area-element,
            .area-block{
                text-align:inherit;
            }
        }
    }

    // wyrownnanie pion
    &.align-v-middle{
        .#{$root}-inner{
            .row{
                align-items:center;
            }
        }
    }
    &.align-v-top{
        .#{$root}-inner{
            .row{
                align-items:flex-start;
            }
        }
    }
    &.align-v-bottom{
        .#{$root}-inner{
            .row{
                align-items:flex-end;  
            }
        }
    } 
    &.align-v-default{
        .#{$root}-inner{
            .area-element,
            .area-block{
                align-items:baseline;
            }
        } 
    } 

    // odstepy
    $padding-sizes: (
        0: 0,
        sm: 2.5rem,
        md: 4rem, 
        lg: 6rem,
    ) !default;
    @each $size, $val in $padding-sizes {
        &.padding-t-#{$size}{
            @include bp(padding-top,(  
                xs:$val*.4,
                md:$val*.8,
                lg:$val*.86,
                xl:$val*.92,   
                xxl:$val   
            ));  
        }
        &.padding-b-#{$size}{
            @include bp(padding-bottom,(  
                xs:$val*.4,
                md:$val*.8,
                lg:$val*.86,
                xl:$val*.92,   
                xxl:$val   
            ));  
        }
    }

    // wewnetrzne odstepy
    $inner-margin-sizes: (
        0: 0,
        sm: 2.5rem,
        md: 4rem,
        lg: 6rem,
        set: 0,
    ) !default;
    @each $size, $val in $inner-margin-sizes {
        &.inner-margin-#{$size}{
            .row{
                margin:0;
                padding:0;
                div[class^="col-"]{
                    padding:0;
                    .col-inner{
                        padding:$val; 
                    }
                }
            }
        }
    }
    @each $size, $val in $padding-sizes {
        &.inner-margin-set .row div[class^="col-"].padding-a-#{$size} .col-inner{
            padding:$val;
        }
        &.inner-margin-set .row div[class^="col-"].padding-l-#{$size} .col-inner{
            @include media-breakpoint-up(lg) {
                padding-left:$val;
            } 
        }
        &.inner-margin-set .row div[class^="col-"].padding-r-#{$size} .col-inner{
            @include media-breakpoint-up(lg) {
                padding-right:$val;
            } 
        }
    }
}