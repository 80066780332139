$logo-width:214px;
$logo-height:107px;
$root: quote(navbar); // #{$root}
.#{$root}-wrapper{
	width:100%; 
    body.editmode &{ 
        pointer-events:none;
    }
    position:absolute; 
    top:0;
    left:0;
    z-index:99;
	@include media-breakpoint-up(lg) {
		position:fixed;
        body.editmode &{
            position:absolute; 
        }
	}
	.#{$root}{
		background:transparent;
        width:100%;
        position:relative;
        @include py(0rem);
        @include transition(all .3s ease(in-out-quad));
        &:before{ 
            content:''; 
            top:0;
            left:0;
            width:100%;
            height:0%;
            position:absolute;
            background:rgba($color-secondary,.92);
            z-index:-1;
            @include transition(height .3s ease(in-out-quad));
        }
		.#{$root}-toggler{
            @include transition(all .25s ease(in-out-quad));
            outline:none;
            position:relative;
            z-index:1;
            top:1rem;
            @include size(60px);
            > em{
                display:none;
            }
            > span{
                position:absolute;
                height:2px;
                border-radius:2px;
                overflow:hidden;
                background:$color-secondary;
                display:block;
                left:25%;
                @include transition(top .2s 0s ease(in-out-quad), width .2s .2s ease(in-out-quad), transform .2s 0s ease(in-out-quad));
                &:nth-child(1){
                    @include rotate(45);
                    top:49px;
                    width:50%;
                }
                &:nth-child(2){
                    top:49px;
                    width:50%;
                    @include rotate(-45);
                }
                &:nth-child(3){
                    top:49px;
                    width:0%;
                }
            }
            &:before{
                content:'';
                position:absolute;
                width:100%;
                left:0;
                bottom:-100%;
                height:200%;
                @include transition(height .2s 0s ease(in-out-quad));
                //@include rotate(180);
                right:-1px;
                left:auto;
                background:$light;
                z-index:-1;
            }
            &:hover{

            }
            &.collapsed{
                top:1rem;
                > span{
                    @include rotate(0);
                    background:$light;
                    &:nth-child(1){
                        top:20px;
                        width:50%;
                    }
                    &:nth-child(2){
                        top:30px;
                        width:50%;
                    }
                    &:nth-child(3){
                        top:40px;
                        width:25%;
                    }
                }
                &:before{
                    height:0%;
                }
                &:hover{
                    
                }
            }
		}
        .#{$root}-header{ 
            position:relative;
            overflow:hidden; 
            z-index:1;
            @include transition(all .3s ease(in-out-quad));
            @include bp(top,(
                xs:1rem,
                md:1rem,
                lg:1.5rem,
                xl:2.0rem,
                xxl:2.5rem
            )); 
            @include bp(left,(
                xs:1rem,
                md:1rem,
                lg:1rem,
                xl:1rem,
                xxl:1rem
            ));
            @include bp(width,(
                xs:$logo-width*.7,
                md:$logo-width*.7, 
                lg:$logo-width*.7, 
                xl:$logo-width*.75, 
                xxl:$logo-width*.8
            ));
            @include bp(height,(
                xs:$logo-height*.7,
                md:$logo-height*.7,
                lg:$logo-height*.7, 
                xl:$logo-height*.75, 
                xxl:$logo-height*.8 
            ));
            .#{$root}-brand{  
                overflow:hidden; 
                @include transition(all .3s ease(in-out-quad));
                @include size(100%);
                padding:0;
                svg{
                    @include img-fluid();
                    .subname{
                        opacity:1;
                        @include transition(all .3s ease(in-out-quad));
                    }
                    .name{
                        @include transition(all .3s ease(in-out-quad));
                    }
                }
                img{
                    @include img-fluid(); 
                }
            }
        }
        .#{$root}-collapse{
            justify-content:flex-end;
            position:relative;
            z-index:1;
            @include bp(top,(
                xs:1rem,
                md:1rem,
                lg:1.5rem,
                xl:2.0rem,
                xxl:2.5rem
            )); 
            @include media-breakpoint-down(lg) {
                background:$light;
                top:3em;
            }
            @include transition(all .3s ease(in-out-quad));
            .navbar-nav{ 
                display:block;
                position:relative;
                align-items:center;
                display:flex;
                flex-direction:row;
                flex-wrap:nowrap;
                @include media-breakpoint-down(lg) {
                    flex-direction:column;
                }
                > li{
                    font-family:$secondary-font-family;
                    line-height:1.3;
                    @include transition(all .3s ease(out-quad));  
                    @include media-breakpoint-down(lg) {
                        display:block;   
                        width:100%; 
                        text-align:center;
                        border-bottom:1px solid darken($light,9%);
                    }
                    a{ //.nav-link
                        text-decoration:none;
                        outline:none;
                        position:relative;
                        color:$light;
                        @include media-breakpoint-down(lg) {
                            color:$gray-700;   
                        }
                        @include bp(padding,(
                            xs:1.5rem 1.5rem,
                            md:1.9rem 1rem,
                            lg:2.1rem 1rem,
                            xl:2.3rem 1rem,
                            xxl:3rem 1rem
                        ));
                        white-space:nowrap;
                        @include transition(all .3s ease(in-out-quad));
                        > h5{
                            margin:0;
                            padding:0;
                            font-weight:400;
                            @include bp(font-size,(
                                md:.8rem,
                                lg:.8rem,
                                xl:.85rem,
                                xxl:.9rem
                            ));
                            > span{
                                display:block;
                            }
                        }
                        &:before{
                            content:'';
                            position:absolute;
                            top:0;
                            left:0;
                            height:100%;
                            width:0%;
                            background:rgba(#fff,.05);
                            @include transition(all .3s ease(out-cubic)); 
                        }
                        &:hover{
                            &:before{ 
                                width:100%;   
                            }
                        }
                    } 
                    &.nav-item-has-submenu{
                        .nav-submenu{
                            display:none;
                        }
                    }

                    @for $i from 1 through 20 {
                        &:nth-child(#{$i}){ 
                            > a{
                                //background:rgba(darken(lighten($color-primary,5%), random(10)*2), (random(10) * 0.01 + .8)); 
                            } 
                        }
                    }
                    &.search{
                        text-indent:-999px;
                        min-width:150px;
                        height:42px;
                        overflow:hidden; 
                        position:relative;
                        margin-left:1rem;
                        margin-right:1rem;
                        @include transition(all .3s ease(in-out-quad));
                        form{
                            @include size(100%,40px);
                            position:absolute;
                            top:0;
                            left:0;
                            border:1px solid darken($light,9%);
                            border-radius:42px;
                            @include transition(all .3s ease(in-out-quad));
                            > input{
                                border:0;
                                background:transparent;
                                position:absolute;
                                top:0;
                                left:0;
                                @include size(100%);
                                font-size:.85rem;
                                line-height:42px;
                                padding-left:1rem;
                                padding-right:4rem;
                                color:$gray-500;
                                &:focus{
                                    outline:none;
                                }
                            }
                            > button{
                                background:transparent;
                                border:0;
                                position:absolute;
                                top:0;
                                right:0;
                                color:rgba($dark,0);
                                @include size(42px);
                                overflow:hidden; 
                                svg{
                                    position:absolute;
                                    top:7px;
                                    right:1rem;
                                    margin:0;
                                    @include size(24px);
                                    fill:$gray-500;
                                }
                            }
                        }
                        &:hover,
                        &:focus{
                            min-width:220px;
                            form{
                                border-color:darken($light,25%);
                                > input{
                                    color:$gray-800;
                                }
                            }
                        }
                    }
                    &.social{
                        @include size(36px);
                        text-indent:-999px;
                        overflow:hidden;
                        margin-left:1rem;
                        
                        > a{
                            @include size(100%);
                            display:block;
                        }
                        background-size:contain;
                        background-repeat:no-repeat;
                        background-position:50% 50%;
                        &.fb{
                            background-image:url(/static/img/ico-social-fb.png);
                        }
                        &.tw{
                            background-image:url(/static/img/ico-social-tw.png);
                        }
                        &.yt{
                            background-image:url(/static/img/ico-social-yt.png);
                        }
                    }
                }
            }
            &.show{ 
                .navbar-nav{
                    > li{
                        @for $i from 1 through 20 {
                            &:nth-child(#{$i}){ 
                                //transition-delay:$i*0.1s;
                            }
                        }
                    }
                }
            }
        }
        &.#{$root}-fixed{ 
            position:fixed;
            @include py(0rem);
            &:before{
                height:100%; 
            }
            .#{$root}-toggler{
                &:before{
                    width:100%;
                    left:0;
                    right:auto;
                }
            }
            .#{$root}-header{  
                top:.1rem;
                @include bp(width,(md:$logo-width*.4, lg:$logo-width*.45, xl:$logo-width*.5, xxl:$logo-width*.4));
                @include bp(height,(md:$logo-height*.4, lg:$logo-height*.45, xl:$logo-height*.5, xxl:$logo-height*.4));
                .#{$root}-brand{
                    svg{
                        .subname{ 
                            opacity:0;
                            @for $i from 1 through 20 {
                                //&:nth-child(#{$i}){ 
                                    //transition-delay:$i*0.01s;
                                //}
                            }
                        }
                        .name{
                            fill:$light;
                        }
                    }
                }
            } 
            .#{$root}-collapse{
                top:0rem;
                .navbar-nav{ 
                    > li{
                        a{ 
                            color:$light;
                            @include bp(padding,(
                                md:1.45rem 1rem,
                                lg:1.45rem 1rem,
                                xl:1.45rem 1rem,
                                xxl:1.45rem 1rem
                            ));
                        }
                    }
                }
            }
        }
	}
}