.content-area{
    //overflow-x:hidden;
    width:100%;  
    img{  
        @include img-fluid();
    }
    .figure{
        display:block;
        margin:0;
        img{
            @include img-fluid();
        }
    }
    
    @import "area/brick.scss"; 
    
    @import "area/element_image.scss";
    @import "area/element_video.scss";
    @import "area/element_icon.scss";
    @import "area/element_lead.scss";
    
    @import "area/block_accordion.scss";
    @import "area/block_gallery.scss";
    
    body.editmode & .pimcore_area_composer-layout{ // visual grid for better edit
        position:relative;
        .editmode-area{ // stripe with cutomization nav selects
            opacity:0;
            @include transition(opacity .3s ease(in-out-quad));
            background:$gray-400;
            position:relative;
            top:0px;
            //border:1px solid $gray-500;
            padding:.6rem .6rem .25rem;
            .x-boundlist-item,
            .x-boundlist-selected,
            .x-form-text-default{
                font-size:12px !important;
                line-height:20px !important; 
            } 
        }
        .editmode-area-inner,
        .editmode-area-select{
            opacity:0;
            @include transition(opacity .3s ease(in-out-quad));
            background:$gray-200;
            margin-bottom:1px;
            position:relative;
            padding:.6rem .6rem .25rem;
        }
        &:after{
            content:'';
            border:1px dashed rgba($dark,.1);
            position:absolute;
            top:-0px;
            left:-0px;
            width:calc(100% + 0px);
            height:calc(100% + 0px);
            z-index:9;
            pointer-events:none; 
        } 
        &.active{
            .editmode-area{
                opacity:1;
            }
            .editmode-area-inner,
            .editmode-area-select{
                opacity:1;
            }
        }
        .pimcore_area_buttons.top{
            min-width:265px !important;
        }
        &:hover{
            &:after{
                border-color:$dark; 
            }
        }
    }
}