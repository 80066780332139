// generic transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}
@mixin rotateZ ($deg) {
	@include transform(rotateZ(#{$deg}deg));
}
@mixin rotateX ($deg) {
	@include transform(rotateX(#{$deg}deg));
}
@mixin rotateY ($deg) {
	@include transform(rotateY(#{$deg}deg));
}
 
// scale
@mixin scale($scale) {
	@include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}
@mixin translateX($x) {
	@include transform(translateX($x));
}
@mixin translateY($y) {
	@include transform(translateY($y));
}
// skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
	moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}

@mixin transform-style($val) {
  -webkit-transform-style: $val;
  -moz-transform-style: $val;
  -ms-transform-style: $val;
  transform-style: $val;
}

@mixin perspective($val) {
  -webkit-perspective: $val; 
  -moz-perspective: $val; 
  -ms-perspective: $val; 
  perspective: $val;
}

@mixin perspective-origin($val){
  -webkit-perspective-origin: $val;
  -moz-perspective-origin: $val;
  -ms-perspective-origin: $val;
  perspective-origin: $val;
}
