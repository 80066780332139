@import "../bootstrap4/buttons"; 

.btn-swipe-y{
	@include button-shared-props();
	border:$btn-border-width solid transparent;
	@include button-size-set;
	&:before{
		left:0;
		top:0;
		width:100%;
		height:0;
	}
	&:hover,
	&.hover{
		&:before{
			height:100%;
		}	
	}
}
.btn-swipe-invert-y{
	@include button-shared-props();
	border:$btn-border-width solid transparent;
	@include button-size-set;
	&:before{
		left:0;
		bottom:0;
		width:100%;
		height:100%;
	}
	&:hover,
	&.hover{
		&:before{
			height:0%;
		}	
	}
}

.btn-swipe-x{
	@include button-shared-props();
	border:$btn-border-width solid transparent;
	@include button-size-set;
	&:before{
		left:0;
		top:0;
		width:0%;
		height:100%;
	}
	&:hover,
	&.hover{
		&:before{
			width:100%;
		}	
	}
}

.btn-rotoswipe-x{
	@include button-shared-props();
	border:$btn-border-width solid transparent;
	@include button-size-set;
	&:before{
		left:0;
		top:-0%; 
		width:120%; 
		height:110%; 
		@include rotate(-90);
		@include transform-origin(0 bottom);
	}
	&:hover,
	&.hover{
		&:before{
			@include rotate(0);
		}	
	}
}

.btn-rotoswipe-y{
	@include button-shared-props();
	border:$btn-border-width solid transparent;
	@include button-size-set;
	//overflow:visible;
	&:before{
		right:-70%;
		top:0%; 
		width:250%; 
		height:110%; 
		@include rotate(90);
		@include transform-origin(right top); 
	}
	&:hover,
	&.hover{
		&:before{
			@include rotate(0);
		}	
	}
}


.btn-roto3d-x{
	@include button-shared-props();
	border:$btn-border-width solid transparent;
	overflow:visible;
	perspective:500px;
	@include button-size-set(true);
	> span{
		transform-style:preserve-3d;
		display:block;
		border-top:$btn-border-width solid transparent;
		//position:relative;
		@include transform-origin(50% 50%); 
		//@include button-size($btn-padding-y, $btn-padding-x, inherit, $btn-line-height, $btn-border-radius);
		@include rotateX(0);
		@include transition(all .35s ease(in-out-sine));
		&:before{
			content:attr(data-name);
			border-top:$btn-border-width solid transparent;
			position:absolute;
			left:0%;
			top:100%; 
			width:100%; 
			height:100%; 
			z-index:1;
			//line-height:$btn-line-height;
			//@include button-size($btn-padding-y, $btn-padding-x, inherit, $btn-line-height, $btn-border-radius);
			//@include rotate(90);
			@include rotateX(-90);
			//@include button-size($btn-padding-y, $btn-padding-x, inherit, $btn-line-height, $btn-border-radius);
			@include transform-origin(50% 0%); 
			@include transition(all .35s ease(in-out-sine));  
		}
	}
	&:hover,
	&.hover{
		> span{
			//@include transform (rotateX(90deg) translateZ($btn-padding-y*2.4));
			@include rotateX(90);
			margin-top:-21%;
			background:fade(#fff,0);
		}	
	}
	&-lg:hover,
	&-lg.hover{
		> span{
			//@include transform (rotateX(90deg) translateZ($btn-padding-y-lg*1.7) !important);
			margin-top:-24%;
		}
	}
	&-sm:hover,
	&-sm.hover{
		> span{
			margin-top:-18%;
			//@include transform (rotateX(90deg) translateZ($btn-padding-y-sm*2.4) !important);
		}
	}
}

@each $color, $value in $theme-colors {
    .btn-swipe-x-#{$color} { 
        @include button-swipe-variant($value);
    }
    .btn-swipe-y-#{$color} { 
        @include button-swipe-variant($value);
    }
    .btn-swipe-invert-y-#{$color} {
        @include button-swipe-invert-variant($value);
    }
  .btn-rotoswipe-x-#{$color} { 
    @include button-rotoswipe-variant($value);
  }
  .btn-rotoswipe-y-#{$color} { 
    @include button-rotoswipe-variant($value);
  }
  .btn-roto3d-x-#{$color} { 
    @include button-roto3d-variant($value);
  }
}