$root: quote(snippet); // #{$root}


.#{$root}-testimonial{
    background-color:$color-secondary;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-image:url(/static/img/curiosity-bg.jpg);
    @include media-breakpoint-up(lg) {
        background-attachment:fixed;
    }
    position:relative;
    overflow:hidden;
    h2{
        margin-bottom:2rem;
        text-align:center;

        color:$color-tertiary;
        @include bp(font-size,(  
            md:$h2-font-size*.92,
            lg:$h2-font-size,
            xl:$h2-font-size*1.08,   
            xxl:$h2-font-size*1.16   
        )); 
    }
    .col-testimonial{
        .item{
            padding:0 1em;
            text-align:center;
            img.logo{
                max-width:200px;
                margin:auto;
            }
            div.txt{
                @include bp(min-height,(  
                    lg:240px,
                    xl:190px,   
                    xxl:175px   
                )); 
                p{
                    color:rgba($light,.6);
                }
            }
            div.client{
                position:relative;
                p{
                    color:rgba($light,.7);
                }
                h5{
                    text-align:center;
                    margin-bottom:.5rem;
                    color:$light;
                }
				&:before{
					content:'';
					height:2px;
					width:40px;
					margin:1.5rem auto;
                    display:block;
					background:$color-primary;
				}
            }
        }
    }
}

.#{$root}-portfolio-list{
    h2{
        margin:0rem 0 3rem;
        text-align:center;
        color:$color-tertiary;
        @include bp(font-size,(  
            md:$h2-font-size*.92,
            lg:$h2-font-size,
            xl:$h2-font-size*1.08,   
            xxl:$h2-font-size*1.16   
        )); 

    }
    .portfolio-list{
        .item{
            .col-info{
                position:relative;
                z-index:2;
                padding-bottom:2rem;
                @include media-breakpoint-up(lg) {
                    @include px(5vw);
                }
                .logo{
                    padding:.5rem;
                    //border:1px solid darken($light,5%);
                }
                h5{
                    margin-top:1rem;
                    color:$dark;
                }
                p{
                    color:rgba($dark,.7);
                }
                @extend %btn-more-primary;
            }
            .col-img{
                position:relative;
                z-index:1;
                img{
                    @include media-breakpoint-up(lg) {
                        position:relative;
                        //left:4rem;
                    }
                }
            }
            &:before{
                content:'';
                height:1px;
                width:40%;
                margin:3.5rem auto;
                display:block;
                background:darken($light,10%);;
            }
            &:first-child{
                &:before{
                    display:none;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .portfolio-list{
            .item{
                &:before{
                    margin:1rem auto;   
                }
                .col-img{
                    order:1 !important;   
                }
                .col-info{
                    order:2 !important;  
                }
            }
        }
    }
}

.#{$root}-bundle{
    position:relative;
    overflow:hidden;
    z-index:1;
    &:before{
        content:'';
        @include coverer();
        height:calc(100% - 200px);
        top:200px;
        background:darken($light,3%);
        z-index:-1;
    }
    h2{
        text-align:center;
        margin-bottom:2rem;
        color:$light;
        display:none;
    }
    table{
        border:0;
        width:100%;
        //border-bottom:1px solid darken($light,10%);
        margin-bottom:4.2rem;  
        tr{
            th{
                padding:1.0rem .3rem;
                h5{
                    margin:0rem 0 1rem;
                    font-weight:600;
                    color:$gray-900;
                }
                img{
                    @include img-fluid();
                    width:90% !important;
                    max-width:180px;
                    @include bp(padding,(  
                        md:0 .5rem,
                        lg:0 .8rem,
                        xl:0 1.2rem,
                        xxl:0 1.6rem  
                    ));
                }
            }
        }
        tr{
            td{
                padding:1rem 1rem;
                width:16.666%;
                border-top:1px solid darken($light,20%);
                p{
                    margin:0;
                    font-weight:700;
                    color:$gray-600;
                    font-size:.875rem;
                }
                a{
                    font-weight:600;
                    background-repeat: no-repeat;
                    background-size:30px 15px;
                    background-position:100% 1.3em;
                    padding:1em 3.2em 2em 1em;
                    white-space:nowrap;
                    display:inline-block;
                    font-size:.925rem;
                    text-decoration:none;
                    background-image: url('data:image/svg+xml, %3Csvg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="15" viewBox="0 0 40 20" xml:space="preserve"%3E%3Cg style="stroke:%23C32385;"%3E%3Cline x1="0" y1="10" x2="40" y2="10"%3E%3C/line%3E%3Cline x1="32" y1="0" x2="40" y2="10"%3E%3C/line%3E%3Cline x1="32" y1="20" x2="40" y2="10"%3E%3C/line%3E%3C/g%3E%3C/svg%3E ');
                    @include transition(all .3s ease(in-out-quad));
                }
            }
        }
    }
    .tbl-horizontal{
        table{
            tr{
                td{
                    p{
                        margin:.8em .5em;
                        font-weight:400;
                        color:$gray-900;
                        s{
                            color:rgba($dark,.4);
                        }
                    }
                    &:nth-child(2){ // preselected
                        background-color:$color-secondary;
                        border-top-color:rgba($light,.2);
                        p{
                            color:rgba($light,.8);
                            s{
                                color:rgba($light,.4);
                            }
                        }
                    }
                }
                &:last-child{
                    td{
                        padding-top:2em;
                        border-top:1px solid darken($light,30%);
                        &:nth-child(2){
                            background-color:rgba($color-secondary,.05);
                        }
                    }
                }
            }
        }
    }
    .tbl-vertical{
        table{
            tr{
                td{
                    p{
                        font-weight:700;
                    }
                    &:nth-child(1){
                        p{
                            font-weight:400;
                        }
                    }
                    &:nth-child(4){ // preselected
                        background-color:$color-secondary;
                        border-top-color:rgba($light,.2);
                        p{
                            color:$gray-200;
                        }
                    }
                }
                &:last-child{
                    td{
                        padding-top:2em;
                        border-top:1px solid darken($light,30%);
                        &:nth-child(4){
                            background-color:rgba($color-secondary,.05);
                        }
                    }
                }
            }
        }
    }
    @extend %btn-more-primary;
    .col-title{
        @include media-breakpoint-up(lg) {
        margin-top:74px;
        }
    }
    .col-info,
    .col-ico{
        svg{
            @include size(48px);
        }
    }
    @include media-breakpoint-down(lg) {
        padding-top:1rem;
        &:before{
            height:calc(100% - 100px);
            top:100px;
        }
        .col-table{
            overflow:scroll;
        }
        table{
            min-width:580px;
            tr{
                th{
                    h5{
                        font-size:1rem;
                    }
                    img{
                        max-width:100px;
                    }
                }
            }
            tr{
                td{
                    padding:.5rem .2rem;
                    p{
                        font-size:.8rem;
                    }
                    a{
                        background-size:30px 15px;
                        background-position:100% 1.3em;
                        padding:1em 1.2em 2em 0em;
                        font-size:.75rem;
                        background-image: url('data:image/svg+xml, %3Csvg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 40 20" xml:space="preserve"%3E%3Cg style="stroke:%23C32385;"%3E%3Cline x1="32" y1="0" x2="40" y2="10"%3E%3C/line%3E%3Cline x1="32" y1="20" x2="40" y2="10"%3E%3C/line%3E%3C/g%3E%3C/svg%3E ');

                    }
                }
            }
        }
    }
}

.#{$root}-curiosity{
    //background:$color-secondary;
    background-color:$color-secondary;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-image:url(/static/img/curiosity-bg.jpg);
    @include media-breakpoint-up(lg) {
        background-attachment:fixed;
    }
    position:relative;
    overflow:hidden;
    z-index:1;
    text-align:center;
    h3{
        text-align:center;
        margin-bottom:2rem;
        color:$light;
    }
    div.leadtxt{
        p{
            color:rgba($light,.9);
            font-weight:600;
            font-size:1.0rem;
        }
    }
    div.fulltxt{
        p{
            color:rgba($light,.6);
            font-size:.825rem;
        }
    }

    @extend %btn-more-primary;
    .btn-more{
        margin:1.5rem auto 0;
        svg{
            stroke:$light;
        }
    }
    @include media-breakpoint-down(lg) {
        padding:2rem $grid-gutter-width/2;
    }
}

.#{$root}-section-content{
    padding-top:0;
    .col-info{
        &.col-lft{
            @include media-breakpoint-up(lg) {
                padding-right:5vw;//2*$grid-gutter-width;
            }
        }
        &.col-rgt{
            @include media-breakpoint-up(lg) {
                padding-left:5vw;//2*$grid-gutter-width;
            }
        }
        svg.icon{
            display:block;
            fill:$color-primary;
            @include size(46px);
            margin-bottom:.5em;
        }
        h2{
            margin-bottom:.8em;
            color:$color-tertiary;
            @include bp(font-size,(  
                md:$h3-font-size*.92,
                lg:$h3-font-size,
                xl:$h3-font-size*1.08,   
                xxl:$h3-font-size*1.16   
            )); 
        }
        p.lead{
            @include media-breakpoint-up(lg) {
                //width:80%;
            }
            font-weight:600;
            color:$gray-800;
        }
        p{
            font-weight:400;
            color:$gray-600;
        }
        @extend %btn-more-primary;
        .btn-more{
            font-weight:600;
        }
    } 
    @include media-breakpoint-down(lg) {
        text-align:center;
        .col-info{
            svg.icon{
                margin:2rem auto 1rem;
            }
        }
        .col-img{
            display:none;
        }
    }
}

.#{$root}-about{
    padding-bottom:0;
    .col-img{
        .inner{
            /*
            @extend %img-blend;
            background: 
    linear-gradient($color-primary 0%, $color-tertiary 90%),
                url(attr(data-img);
  background-blend-mode:screen;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
            */
        }
    }
    .col-info{
        @include media-breakpoint-up(lg) {
            padding-right:5vw;//2*$grid-gutter-width;
        }
        svg.icon{
            display:block;
            fill:$color-primary;
            @include size(48px);
        }
        h2{
            color:$color-tertiary;
            @include bp(font-size,(  
                md:$h2-font-size*.92,
                lg:$h2-font-size,
                xl:$h2-font-size*1.08,   
                xxl:$h2-font-size*1.16   
            )); 
        }
        p.lead{
            @include media-breakpoint-up(lg) {
                width:80%;
            }
            font-weight:600;
            color:$gray-800;
        }
        p{
            font-weight:400;
            color:$gray-600;
        }
        @extend %btn-more-primary;
    } 
    @include media-breakpoint-down(lg) {
        text-align:center;
        .col-img{
            display:none;   
        }
    }
}

.#{$root}-offer{
    overflow:hidden;
    padding:0; 
    .col-img{
        .inner{ 
            //@extend %img-blend;
        }
    }
    .col-info{
        @include media-breakpoint-up(lg) {
            padding-left:$grid-gutter-width*1.5;
        }
        svg.icon{
            display:block;
            fill:$color-tertiary;
            @include size(46px);
        }
        h2{
            color:$color-tertiary;
            @include bp(font-size,(  
                md:$h2-font-size*.92,
                lg:$h2-font-size,
                xl:$h2-font-size*1.08,   
                xxl:$h2-font-size*1.16   
            )); 
        }
        p.lead{
            @include media-breakpoint-up(lg) {
                width:80%;
            }
            font-weight:600;
            color:$gray-800;
            margin-bottom:3rem;
        }
        .col-item{
            padding-top:2rem;
            padding-bottom:4rem;
            svg.icon{
                fill:$color-primary;
            }
            h4{
                font-weight:600;
            }
            @extend %btn-more-primary;
            .btn-more{
                color:$color-primary;
            }
        }
        @extend %btn-more-primary;
        .row{
            position:relative;
            z-index:1;
            &:before{
                content:'';
                display:block;
                position:absolute;
                top:0;
                left:-$grid-gutter-width*1;
                width:75vw;
                height:100%;
                background:$light;
                z-index:-1;
            }
        }
    } 
    @include media-breakpoint-down(lg) {
        text-align:center;
        .col-img{
            margin-bottom:2rem;
        }
        .col-info{
            .row{
                &:before{
                    display:none;
                }    
            }
            .col-item{
                padding:2rem $grid-gutter-width;
                border-top:1px solid darken($light,5%);
                .ico{
                    svg{
                        margin:0 auto 2rem;
                    }
                }
            }
        }
    }
}

.#{$root}-client{
    position:relative;
    overflow:hidden;
    z-index:1;
    h5{
        text-align:center;
        margin-bottom:2rem;
    }
    .col-logo{
        .item{
            padding:1rem 2rem;
            img{
                @include img-fluid();
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                opacity:.6;
                @include transition(all .4s ease(in-out-quad));
            }
            &:hover{
                img{
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                    opacity:1;
                }
            }
        }
    }
    &:after{
        content:'';
        @include coverer();
        background:rgba($dark,.06);
        z-index:2;
        pointer-events:none;
    }
}

.#{$root}-technology{
    position:relative;
    overflow:hidden;
    //border-bottom:1px solid darken($light,5%);
    h5{
        text-align:center;
        margin-bottom:2rem;
    }
    .col-logo{
        .item{
            padding:0rem;
            text-align:center;
            h6{
                margin-top:1rem;
                color:$gray-400;
            }
            img{
                @include img-fluid(); 
            }
        }
    }
}

.#{$root}-portfolio{
    padding-top:0;
    h2{
        margin-bottom:2rem;
        text-align:center;

        color:$color-tertiary;
        @include bp(font-size,(  
            md:$h2-font-size*.92,
            lg:$h2-font-size,
            xl:$h2-font-size*1.08,   
            xxl:$h2-font-size*1.16   
        )); 

    }
    .portfolio-carousel{
        .carousel-item{
            overflow:hidden;
            .col-info{
                position:relative;
                z-index:2;
                @include media-breakpoint-up(lg) {
                    //top:1rem;
                }
                h4{
                    color:$light;
                }
                p{
                    color:rgba($light,.7);
                }
                @extend %btn-more-light; 
            }
            .col-img{
                position:relative;
                z-index:1;
                img{
                    @include media-breakpoint-up(lg) {
                        position:relative;
                        left:4rem;
                    }
                }
                &:before{
                    @include media-breakpoint-up(lg) {
                        content:'';
                        display:block;
                        position:absolute;
                        top:10%;
                        right:50%;
                        width:75vw;
                        height:80%;
                        background:darken($color-secondary,4%);
                        z-index:-1;
                    }
                }
                &:after{
                    @include media-breakpoint-up(lg) {
                        content:'';
                        display:block;
                        position:absolute;
                        top:10%;
                        left:50%;
                        width:40vw;
                        height:80%;
                        background:darken($light,5%);
                        z-index:-1;
                    }
                }
            }
            .col-fact{
                padding-top:2rem;
                @include media-breakpoint-down(lg) {
                    padding-bottom:2rem;
                }
                .ico-num{
                    text-align:center;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    background-size:contain;
                    background-repeat:no-repeat;
                    background-position:50% 50%;
                    background-image:url(/static/img/ico-portfolio-fact-bg.png);
                    @include size(120px);
                    h6{
                        color:$light;
                        margin:0;
                    }
                    @include media-breakpoint-up(lg) {
                        float:left;
                        margin-right:2rem;
                    }
                }
                h5{
                    font-weight:600;
                    margin-top:2rem;
                }
                p{
                    line-height:1.3;
                    color:$gray-600;
                }
            }
        }
        .carousel-control{
            &-next,
            &-prev{
                height:75%;
                svg{
                    fill:$color-tertiary;
                }
            }
        }
    }
    .col-btn{
        position:relative;
        @include media-breakpoint-up(lg) {
            top:-5rem;
            text-align:right;
        }
        @extend %btn-more-primary;
        .btn-more{
            font-weight:600;
        }
    }
    @include media-breakpoint-down(lg) {
        text-align:center;
        padding:3rem 0;
        background:$color-secondary;   
        .portfolio-carousel{
            .item{
                padding:0rem $grid-gutter-width/2;
                .col-info{
                    .btn-more{
                        margin:0 auto 1.5rem auto;
                        display: inline-block;
                    }
                }
                .col-fact{
                    display:block;
                    .ico-num{
                        margin:1rem auto;   
                    }
                    h5{
                        color:$light;
                    }
                    p{
                        color:rgba($light,.7);
                    }
                }
            }
        }
    }
}

.#{$root}-contact{
    background-color:$color-secondary;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-image:url(/static/img/contact-bg.jpg);
    @include media-breakpoint-up(lg) {
        background-attachment:fixed;
    }
    h2{
        margin-bottom:2rem;
        text-align:center;
        color:$light;
        @include bp(font-size,(  
            md:$h2-font-size*.92,
            lg:$h2-font-size,
            xl:$h2-font-size*1.08,   
            xxl:$h2-font-size*1.16   
        )); 

    }
    .col-form{
        .form-group{
            margin-top:1rem;
            label{
                color:$light;
            }
            .form-control{
                background:transparent;
                border:0;
                border-bottom:1px solid rgba($light,.5);
                color:rgba($light,.8);
                ::-webkit-input-placeholder{
                    color:rgba($light,.4);
                }
                ::-moz-placeholder{
                    color:rgba($light,.4);
                }
                :-ms-input-placeholder{
                    color:rgba($light,.4);
                }
                :-moz-placeholder{
                    color:rgba($light,.4);
                }
                &:focus{
                    background:transparent;
                    border-bottom-color:rgba($light,1);
                    box-shadow:0 0 0 1px rgba($light,.2);
                }
            }
        }
        .form-check{
            padding-left: 2rem;
            input.form-check-input{
                padding:20px;
                width:22px;
                height:22px;
                margin-top:0.05rem;
                margin-left:-2rem;
            }
            &-label{
                color:rgba($light,.55);
                font-size:.825rem;
                > a{
                    color:rgba($light,.7);
                    text-decoration:underline;
                }
            }
        }
        .col-btn{
            text-align:center;
            .btn-submit{
                display:inline-block;
                background:$light;
                color:$dark;
                padding:.8rem 1.5rem;
                cursor:pointer;
                border:0;
                font-weight:600;
                margin:2rem auto 0;
                display:flex;
                align-items:center;
                svg{
                    margin-left:1rem;
                    stroke-width:2px;
                    stroke:$dark;
                }
                &:hover{
                    background:$color-primary;
                    color:$light;
                    @include transition(all .3s ease(in-out-quad));
                    svg{
                        stroke:$light;
                        @include transition(all .3s ease(in-out-quad));
                    }
                }
            }
        }
        .msg{
            text-align:center;
            &-success{
                h3{
                    color:$light;
                    margin-bottom:1em;
                }
            }
            .btn-back{
                display:inline-block;
                background:$light;
                color:$dark;
                padding:.8rem 1.5rem;
                cursor:pointer;
                border:0;
                font-weight:600;
                text-decoration:none;
                svg{
                    vertical-align:middle;
                    margin-left:1rem;
                    stroke-width:2px;
                    stroke:$dark;
                }
                &:hover{
                    background:$color-primary;
                    color:$light;
                    @include transition(all .3s ease(in-out-quad));
                    svg{
                        stroke:$light;
                        @include transition(all .3s ease(in-out-quad));
                    }
                }
            }
        }
    }
}

.#{$root}-blog{
    h2{
        margin-bottom:2rem;
        text-align:center;

        color:$color-tertiary;
        @include bp(font-size,(  
            md:$h2-font-size*.92,
            lg:$h2-font-size,
            xl:$h2-font-size*1.08,   
            xxl:$h2-font-size*1.16   
        )); 

    }
    @extend %blog-item;
    @include media-breakpoint-down(lg) {
        text-align:center;
        .item{
            > a{
            .info{
                .btn-more{
                    display:inline-block;
                }
            }
            }

        } 
    }
}

.#{$root}-footer{ 
    @include bp(padding-top,(xs:5rem,sm:3rem,md:4rem,lg:4.3rem,xl:4.6rem,xxl:5rem));
    @include bp(padding-bottom,(xs:2rem,sm:2.2rem,md:2.4rem,lg:2.6rem,xl:2.8rem,xxl:3rem)); 
    background:$color-secondary;
    .col-copy p{
        margin-top:1em; 
        color:rgba($light,.5);  
        font-size:.825rem; 
        a{
            color:rgba($light,.4);  
            font-size:.825rem; 
        }
    }
    .col-logo{
        svg{
            width:180px;
        }
    }  
    a.credits{
        font-size:.8rem;
        color:$gray-500;
        position:relative;
        text-decoration:none;
    }
    h6{
        font-weight:600;
        color:$light;
        font-size:1rem;
        margin-bottom:1rem;
    }
    p{
        color:rgba($light,.7);
        font-size:.825rem;
        > a{
            color:rgba($light,.7);
            text-decoration:none;
        }
    }
    ul{
        list-style:none;
        margin:0;
        margin-bottom:1.25rem;
        padding:0em;
        li{
            display:block;
            padding:0 0em;
            > a{
                font-size:.8rem;
                color:rgba($light,.7);
                position:relative;
                text-decoration:none;
            }
        }
        &.social{
            li{
                display:inline-block;
                svg{ 
                    @include size(28px);
                    fill:$light;
                    margin-left:0;
                    margin-right:1em;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        text-align:center;
        h6{
            margin-top:1rem;
        }
        .col-logo{
            svg{
                margin:2rem auto;
                width:120px;
            }
        }
    }
}

.#{$root}-btn{
    padding-top:0;
    text-align:center;
    ul{
        list-style:none;
        padding:0;
        margin:0;
        //display:flex;
        //flex-direction:row;
        //flex-wrap: nowrap;
        //justify-content: space-around;
        //align-items:center;
        > li{
            padding:0;
            margin:0 2vw;
            display:inline-block;
            > a{
                position:relative;
                z-index:1;
                padding:1rem;
                display:block;
                @include size(100%);
                text-decoration:none;
                border:1px solid $color-primary;
                @include transition(all .3s ease(in-out-quad));
                h5{
                    color:$gray-800;
                    font-size:.925rem;
                    font-weight:600;
                    @include transition(all .3s ease(in-out-quad));
                }
                svg{
                    @include transition(all .3s ease(in-out-quad));
                    @include size(52px);
                    fill:$color-primary;
                }
                &:after{
                    content:'';
                    bottom:0;
                    left:0;
                    width:100%;
                    height:0;
                    z-index:-1;
                    position:absolute;
                    background:$color-primary;
                    @include transition(all .3s ease(in-out-quad));
                }
                &:hover{
                    h5{
                        color:$light;
                    }
                    svg{
                        fill:rgba($light,.8);
                    }
                    &:after{
                        height:100%;
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                @include size(170px);
                > a{
                    padding-top:2rem;
                    svg{
                        @include size(48px);
                    }
                }
            }
            @include media-breakpoint-up(xl) {
                @include size(185px);
                > a{
                    padding-top:2rem;
                    svg{
                        @include size(52px);
                    }
                }
            }
            @include media-breakpoint-up(xxl) {
                @include size(200px);
                > a{
                    padding-top:2.4rem;
                    svg{
                        @include size(56px);
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {  
        padding-bottom:1rem;
        ul{
            > li{
                padding-bottoM:1rem;
            }
        }
    }
}
