$block: quote(area-block-gallery);
.#{$block}{
    body.editmode &{
        .pimcore_block_entry{
            vertical-align: top;
            display:inline-block;
            max-width:240px;
        }
    }
}

