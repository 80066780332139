@import "vendor/swiper/swiper.scss";

@import "vendor/magnific-popup/magnific-popup.scss";
@import "vendor/magnific-popup/magnific-popup-effect.scss";

@import "vendor/blueimp-gallery/blueimp-gallery.scss";
@import "vendor/blueimp-gallery/blueimp-gallery-video.scss";
@import "vendor/blueimp-gallery/blueimp-gallery-indicator.scss";

@import "vendor/owl-carousel/owl-carousel.scss";
