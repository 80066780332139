/**
 * Owl Carousel v2.3.3
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *	Owl Carousel - Core
 */
 
.owl-carousel,
div[data-trigger=owl-carousel]{
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden;
		/* fix firefox animation glitch */
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
	}
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	.owl-wrapper {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
	}
	.owl-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		img {
			display: block;
			width: 100%;
		}
	}
	.owl-nav.disabled, .owl-dots.disabled {
		display: none;
	}
	.owl-nav {
		.owl-prev, 
		.owl-next {
			cursor: pointer;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
	.owl-dot {
		cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.owl-nav button {
		&.owl-prev, &.owl-next {
			background: none;
			color: inherit;
			border: none;
			padding:0;
			font: inherit;
		}
	}
	button.owl-dot {
		background: none;
		color: inherit;
		border: none;
		padding:0;
		font: inherit;
	}
	&.owl-loaded {
		display: block;
	}
	&.owl-loading {
		opacity: 0;
		display: block;
	}
	&.owl-hidden {
		opacity: 0;
	}
	&.owl-refresh .owl-item {
		visibility: hidden;
	}
	&.owl-drag .owl-item {
		-ms-touch-action: none;
		touch-action: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	&.owl-grab {
		cursor: move;
		cursor: grab;
	}
	&.owl-rtl {
		direction: rtl;
		.owl-item {
			float: right;
		}
	}
    .owl-nav {
		margin-top: 10px;
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		 button.owl-prev,
         button.owl-next{
			color: #FFF;
			font-size:1.2rem;
			margin: 5px;
			padding:.3rem  1rem;
			background: #D6D6D6;
			display: inline-block;
			cursor: pointer;
			border-radius: 3px;
			&:hover {
				background: #869791;
				color: #FFF;
				text-decoration: none;
			}
		}
		.disabled {
			opacity: 0.5;
			cursor: default;
		}
		&.disabled + .owl-dots {
			margin-top: 10px;
		}
	}
	.owl-dots {
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;
			span {
				width: 10px;
				height: 10px;
				margin: 5px 7px;
				background: #D6D6D6;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: 30px;
			}
			&.active span, &:hover span {
				background: #869791;
			}
		}
	}
}

/* No Js */

.no-js .owl-carousel {
	display: block;
}

/*
 *	Owl Carousel - Animate Plugin
 */

.owl-carousel {
	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeOut {
		animation-name: fadeOut;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}


/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
	transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
	.owl-item {
		.owl-lazy {
			opacity: 0;
			transition: opacity 400ms ease;
		}
		img.owl-lazy {
			transform-style: preserve-3d;
		}
	}
	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: #000;
	}
	.owl-video-play-icon {
		position: absolute;
		height: 80px;
		width: 80px;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -40px;
		background: url("owl.video.play.png") no-repeat;
		cursor: pointer;
		z-index: 1;
		-webkit-backface-visibility: hidden;
		transition: transform 100ms ease;
		&:hover {
			-ms-transform: scale(1.3, 1.3);
			transform: scale(1.3, 1.3);
		}
	}
	.owl-video-playing {
		.owl-video-tn, .owl-video-play-icon {
			display: none;
		}
	}
	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: opacity 400ms ease;
	}
	.owl-video-frame {
		position: relative;
		z-index: 1;
		height: 100%;
		width: 100%;
	}
}
