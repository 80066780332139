$element: quote(area-element-image);
.#{$element}{
    &.image-streched{
        &-left img{
            max-width:none;
            float:right;
            //position: relative;
            //right: -$grid-gutter-width/2;
        }
        &-right img{
            max-width:none;
            float:left;
            //position: relative;
            //left: -$grid-gutter-width/2;
        }
    }
    &.image-overlay{
        .figure{
            .figure-caption-txt{
                position: absolute;
                bottom: 10%;
                left: 10%;
                width: 80%;
                background:rgba($white,.8);
                padding: 3rem;
            }
        }
    }
    &.image-with-content{
        border: 1px solid rgba(0,0,0,0.125);
        margin-bottom:$spacer*3;
        .figure-caption-txt{ 
            background:darken($light,2%);
            //color:$light;
            padding:$spacer*2 2vw $spacer*1 2vw;
            p{
                //color:rgba($light,.8);
            }
            ol{
                counter-reset:number;
                list-style:none;
                margin:.2em 0 0 0em;
                padding:0;
                > li{
                    position:relative;
                    counter-increment:number;
                    padding:1.8em 0 1em;
                    //color:rgba($light,.8);
                    @include media-breakpoint-up(lg){
                        padding:.45em 1.5em .6em 2.5em;
                        display:inline-block;
                        width:48%;
                    }
                    &:before{
                        content:counter(number);
                        position:absolute;
                        left:50%;
                        margin-left:-15px;
                        @include media-breakpoint-up(xl) {
                            left:0;
                            margin-left:0;
                        }
                        top:.6em;
                        z-index:1;
                        @include size(26px);
                        color:rgba($color-primary,.8);
                        border-radius:50%;
                        line-height:23px;
                        border:1px solid rgba($color-primary,.8);
                        display:block;
                        text-indent:0;
                        font-size:.8em;
                        font-weight:300;
                        text-align:center;
                        font-family:$secondary-font-family;
                        @include transition(all .45s ease(out-cubic)); 
                    }
                    &:after{
                        content:'';
                        display:block;
                        position:absolute;
                        top:0;
                        left:-140%;
                        width:120%;
                        height:100%;
                        @include transition(all .45s ease(out-cubic)); 
                        z-index:-1; 
                    }
                }
            }

        } 
    }
} 
.col-set-6-6 .#{$element}{
    &.image-streched-left img,
    &.image-streched-right img{
        width:50vw;
    }
}
.col-set-4-8 .#{$element}{
    &.image-streched-left img{
        width:38vw;
    }
    &.image-streched-right img{
        width:62vw;
    }
}
.col-set-8-4 .#{$element}{
    &.image-streched-left img{
        width:62vw;
    }
    &.image-streched-right img{
        width:38vw; 
    }
}

.image-streched-left .pimcore_tag_image{
    body.editmode &{
        float: right;
    }
}

.col-element-image{
    .col-inner{
        padding:0 !important;
    }
}
