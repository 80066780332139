%img-blend{ 
    position:relative;
    z-index:1;
    img{
    opacity:.5;
    }
    &:after{
        content:'';
        position:absolute;
        top:0em;
        left:0em;
        display:block;
        @include size(100%);
        //background:rgba($color-primary,.5);
        z-index:2;
        /*
  background-image: linear-gradient( 12deg, 
      rgba($color-primary,.18) 0%, 
      rgba($color-tertiary,.3) 19%, 
      rgba($color-primary,.21) 100%
  );
*/

    }
}

%blog-item{
    .item{ 
        > a{
            display:block;
            text-decoration:none;
            outline:none;
            position:relative;
            padding-bottom:3rem;
            @include transition(all .3s ease(in-out-quad));
            .info{
                p{
                    margin-top:1.6rem;
                    color:$gray-600;
                }
                @extend %btn-more-secondary;
            } 
            .img{
                position:relative;
                overflow:hidden;
                z-index:2;
                .date{
                    display:block;
                    position:absolute;
                    left:0em;
                    top:2em;
                    background:rgba($color-tertiary,.98);
                    color:rgba($light,1);
                    font-family:$secondary-font-family;
                    padding:.3em 1em .3em 3em;
                    font-size:.8rem;
                    z-index:3;
                }
                img{
                    @include img-fluid();
                    @include scale(1);
                    @include transition(all .7s ease(in-out-quad));
                }
                h4{
                    position:absolute;
                    bottom:2rem;
                    left:3rem;
                    width:calc(100% - 6rem);
                    color:$light;
                    font-weight:600;
                    z-index:3;
                    margin:0;
                }
                &:after{
                    content:'';
                    z-index:1;
                    @include coverer();
                    display:block;
                    background:rgba($black,.45);
                    background:linear-gradient(6deg, rgba(0, 0, 0, .7) 0, rgba(0, 0, 0, .1) 50%);
                }
            }
             &:hover{
                .img{
                    img{
                        @include scale(1.2);
                    }
                }
                .info{

                }
            }
        }
    }
}

  
%btn-more-primary{
    .btn-more{ 
        margin-top:1.5rem;
        text-decoration:none;
        outline:none;
        color:$color-primary;
        //display:flex;
        //align-items:center;
        font-size:.925rem;
        white-space:nowrap; 
        svg{
            vertical-align:middle;
            margin-left:1rem;
            stroke-width:2px;
            stroke:$color-primary;
        }
    }
}

%btn-more-light{
    .btn-more{
        margin-top:1rem;
        text-decoration:none;
        outline:none;
        color:$light;
        display:flex;
        align-items:center;
        font-size:.925rem;
        white-space:nowrap; 
        svg{
            margin-left:1rem;
            stroke-width:2px;
            stroke:$light;
        }
    }
}

%btn-more-secondary{
    .btn-more{
        margin-top:1rem;
        text-decoration:none;
        outline:none;
        color:$color-secondary;
        //display:flex;
        //align-items:center;
        font-size:.925rem;
        white-space:nowrap; 
        svg{
            vertical-align:middle;
            margin-left:1rem;
            stroke-width:2px;
            stroke:$color-secondary;
        }
    }
}