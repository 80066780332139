$block: quote(area-block-accordion);
.#{$block}{
    border-bottom:1px solid $border-color;
    .card{
        body:not(.editmode) &{
            border-bottom:0;
        }
        &-header{
            padding:0;
            border:0;
            h3,h4,h5{
                margin:0;
                > span{
                    cursor:pointer;
                    @include user-select(none);
                    display:block;
                    padding:$spacer;
                    em{
                        font-style:normal;
                        display:inline-block;
                    }
                    > span{

                    }
                    body.editmode &{
                        .pimcore_tag_input{
                            display:inline-block;
                            width:90%;
                        }
                    }
                }
            }
        }
    } 
}

