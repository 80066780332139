.blueimp-gallery {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -moz-backface-visibility: hidden;
    position: fixed;
    z-index: 999999;
    overflow: hidden;
    //background: #000;
    //background: rgba(0, 0, 0, 0.9);
    opacity: 0;
    display: none;
    direction: ltr;
    -ms-touch-action: none;
    touch-action: none;
	&:before{
	    content:'';
	    display:block; 
	    position:absolute;
	    bottom:0;
	    height:100%;
	    width:100%;
	    right:0;
		@include transform(scale(.1));
	    z-index:-1;
	    background:rgba(#000,.88);
	    @include transition(all .4s 1s ease(in-out-quad));
	}
    > .slides {
        position: relative;
        height:96%;
        overflow: hidden;
        opacity:0;
        @include transform(translateY(100px));
		@include transition(all .4s .5s ease(in-out-quad));
        > .slide {
            position: relative;
            float: left;
            height: 100%;
            text-align: center;
            -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            -ms-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            > .slide-content {
                position: absolute; 
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                -moz-backface-visibility: hidden;
                margin: auto;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                opacity: 1;
                -webkit-transition: opacity 0.2s linear;
                -moz-transition: opacity 0.2s linear;
                -ms-transition: opacity 0.2s linear;
                -o-transition: opacity 0.2s linear;
                transition: opacity 0.2s linear;
            }
        }
        .slide-loading {
            background: url(/static/img/vendor/blueimp-gallery/loading.gif) center no-repeat;
            background-size: 36px 36px;
            > .slide-content {
                opacity: 0;
            }
        }
        .slide-error {
            background: url(/static/img/vendor/blueimp-gallery/error.png) center no-repeat;
            > .slide-content {
                display: none;
            }
        }
    }
    &-carousel {
        position: relative;
        z-index: auto;
        margin: 1em auto;
        padding-bottom: 56.25%;
        box-shadow: 0 0 10px #000;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        > .slides {
            position: absolute;
        }
    }
    &-display{ // AKTYWNA
        display:block;
        opacity:1;
        &:before{
            height:100%;
            @include transition-delay(0s);
            @include transform(scale(1));
        }
        > .slides{
            @include transform(translateY(0));
            @include transition-delay(.5s);
            opacity:1;
            > .slide > img.slide-content,
            > .slide > .slide-content{
                @include transition-delay(.3s);
                @include transform(scale(1));
            }
        }
        > .slides{
            top:0%;
        }
    }
}


.blueimp-gallery {
    &.blueimp-gallery-display.blueimp-gallery-controls > .prev {
		left:0;
		@include transition-delay(1s);
	}
	&.blueimp-gallery-display.blueimp-gallery-controls > .next {
		right:0;
		@include transition-delay(1s);
	}
	> .prev,
	> .next {
        $size:56px;
		@include transition(all .5s 0s ease(in-out-quad));
		@include transition-delay(0s);
		position:absolute;
		top:44%;
		height:$size;
		width:$size*.8;
		@include media-breakpoint-down(md) {
			@include transform(scale(.7)); 
			top:53%;
		}
		margin-top:-$size/2;
		text-align:center;
		cursor:pointer;
		@include user-select(none);
		&:after,
		&:before{
		    content:'';
		    display:block; 
		    position:absolute;
		}
		&:after{
		    border-right:1px solid rgba($light,.5);
		    border-bottom:1px solid rgba($light,.5);
		    @include size($size/2.5);
		    top:50%;
		    left:50%;
		    margin-top:-$size/5;
		    z-index:1;
            @include transition(all .5s 0s ease(in-out-quad));
		}
		&:before{
		    top:0;
		    height:100%;
		    width:0;
		    z-index:-1;
		    background:rgba(#fff,.88);
		    @include transition(all .25s 0s ease(in-out-quad));
		}
		&:hover{
			&:after{
				border-color:rgba($dark,.8);
			}
			&:before{
				width:100%;
			}
		}
        &.prev{
            left:-$size;
            &:after{
                @include rotate(-225);
                margin-left:0;
            }
            &:before{
                left:0;
            }
            &:hover{
                &:after{
                    margin-left:-$size/8;
                }
            }
        }
        &.next{
            right:-$size;
            &:after{
                @include rotate(-45);
                margin-left:-$size/2.2;
            }
            &:before{
                right:0;
            }
            &:hover{
                &:after{
                    margin-left:-$size/2.8;
                }
            }
        }
    }

	&.blueimp-gallery-display.blueimp-gallery-controls > .close {
		top:.5em;
		@include transition-delay(.9s);
	}
	> .close {
        $size:48px;
		top:-$size;
		@include transition(all .25s 0s ease(in-out-quad));
		@include transition-delay(0s);
		position:absolute;
		height:$size;
		width:$size;
		right:.5em;
		text-align:center;
		cursor:pointer; 
		@include user-select(none);
		opacity:1;
		&:after,
		&:before{
		    content:'';
		    display:block; 
		    position:absolute;
		    background:rgba($light,.4);
		    @include transition(all .25s 0s ease(in-out-quad));
		}
		&:after{
			width:1px;
		    height:60%;
		    top:20%;
		    left:50%;
		    @include rotate(45);
		}
		&:before{
		    height:1px;
		    width:60%;
		    left:20%; 
		    top:50%;
		    @include rotate(45);
		}
		&:hover{
			&:after,
			&:before{
				background:rgba($light,.7);
			}
		}
	}
    
    &.blueimp-gallery-display.blueimp-gallery-controls > .indicator{
        bottom:2rem;
        @include transition-delay(.8s);
    }
    > .indicator {
        width:80%;
		bottom:-150px;
		@include transition(all .5s ease(in-out-quad));
		@include transition-delay(0s);
		position:absolute;
		top:auto;
        right:auto;
		left:10%;
		margin:0 0px;
		padding:0em;
		list-style:none;
		text-align:center;
		line-height:10px;
		@include user-select(none);
		> li {
			display:inline-block;
			@include size(30px);
			@include media-breakpoint-up(md) {
				@include size(66px);
			}
			margin:1em .5em 1em .5em;
			position:relative;
			background:rgba(#000,.2) center no-repeat;
			background-size:cover;
			opacity:.7;
			cursor:pointer;
			@include transition(all .5s ease(in-out-quad));
			&:before{
				content:'';
				display:block;
				width:0%;
				height:0%;
				position:absolute;
				top:50%;
				left:50%;
				background:rgba(#fff,.7);
				z-index:1;
				@include transition(all .45s .1s ease(in-out-quad));
			}
			&.active{
				@include transform(scale(1.2));
				opacity:1;
				border-color:$color-primary;
				&:before{
					top:10%;
					left:10%;
					width:80%;
					height:80%;
				}
			}
			&:hover, 
			&.active {
				opacity:1;
			}
		}
		&-small{
			> li{
				@include size(15px);
				@include media-breakpoint-up(md) {
					@include size(30px);
				}
				margin:.5em .25em .5em .25em;
			}
		}
	}
    .title {
        position: absolute;
        top: 15px;
        left: 15px;
        margin: 0 40px 0 0;
        font-size: 20px;
        line-height: 30px;
        color: #fff;
        text-shadow: 0 0 2px #000;
        opacity: 0.8;
        display: none;
    }
    .play-pause {
        position: absolute;
        right: 15px;
        bottom: 15px;
        width: 15px;
        height: 15px;
        background: url(/static/img/vendor/blueimp-gallery/play-pause.png) 0 0 no-repeat;
        cursor: pointer;
        opacity: 0.5;
        display: none;
        @include user-select(none);
    }
}
    /*
    &.blueimp-gallery-controls{  // WIDOCZNE KONTROLERY
        > .slides{
            top:0%;
        }
        > .slide-info{
            .translateY(0%);
            .title{
                .transition-delay(.7s);
                .opacity(1);
                top:0px;
            }
            .subtitle{
                .transition-delay(.4s);
                .opacity(1);
            }
            .description{
                .transition-delay(1s);
                .opacity(1);
                top:0;
            }
        }
        > .indicator{
            bottom:0px;
            .transition-delay(.8s);
        }
    }*/


.blueimp-gallery-playing > .play-pause {
    background-position: -15px 0;
}

.blueimp-gallery > {
    .prev:hover, .next:hover, .close:hover, .title:hover, .play-pause:hover {
        color: #fff;
        opacity: 1;
    }
}

.blueimp-gallery-controls > {
    .prev, 
    .next, 
    .close, 
    .title, 
    .play-pause {
        display: block;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.blueimp-gallery-single > .prev, 
.blueimp-gallery-left > .prev, 
.blueimp-gallery-single > .next, 
.blueimp-gallery-right > .next, 
.blueimp-gallery-single > .play-pause {
    display: none;
}

.blueimp-gallery > {
    .slides > .slide > .slide-content, 
    .prev, 
    .next, 
    .close, 
    .play-pause {
        @include user-select(none);
    }
}

body:last-child {
    .blueimp-gallery > {
        .slides > .slide-error {
            background-image: url(/static/img/vendor/blueimp-gallery/error.svg);
        }
        .play-pause {
            width: 20px;
            height: 20px;
            background-size: 40px 20px;
            background-image: url(/static/img/vendor/blueimp-gallery/play-pause.svg);
        }
    }
    .blueimp-gallery-playing > .play-pause {
        background-position: -20px 0;
    }
}

* + html .blueimp-gallery > .slides > .slide {
    min-height: 300px;
    > .slide-content {
        position: relative;
    }
}