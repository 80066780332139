@import "variables";

@import "advisage_bootstrap4/advisage_bootstrap";  
@import "vendor"; 
 
@import "loader";
@import "content";
@import "navbar";
@import "top";
@import "area"; 
@import "common"; 

// snippets
@import "snippet"; 

// includes
@import "includes/breadcrumbs";

@import "composer";

@import "blog";  

 
html, 
body,  
.page-container{
    position: relative;
    height: 100%;
}
.page-container{
    //overflow-x:hidden;
    //width:100%;
}

  [class^="icon-"],
  [class*=" icon-"] {
    margin: 10px;
    height: 32px;
    width: 32px;
    fill:$color-primary;
  } 

.jarallax {
    position: relative;
    z-index: 0;
}
.jarallax > .jarallax-img {
    position: absolute;
    object-fit: cover;
    font-family:'object-fit:cover;'; // ofi.js 
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:-1;
}

/*
.swiper-cube{
	position:relative;
	height:800px;
	padding:100px;
    .swiper-container {
        width: 600px;
        height: 600px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -300px;
        margin-top: -300px;
    }
    .swiper-slide {
        text-align: center; 
        font-size: 18px;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        @include bp(width,(md:100px,lg:200px,xl:300px));
    }
}

.swiper-coverflow{
    .swiper-container {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 600px;
        height: 600px;
    }
}
*/