
$root: quote(blog); // #{$root}
.content-#{$root}-list{
	.col-item{
		position:relative;
		display:block;
		text-decoration:none;
		@extend %blog-item;
	}
	.pagination{
        @include clearfix();
		margin:0 auto;
		padding:0em 0 0 0;
		> li{
			display:inline-block;
			margin:1.0em .3em;
			padding:0;
			position:relative;
			&:after{
				display:none;
			}
			> a{
				@include transition(all .3s ease(in-out-quad));
				border:1px solid darken($light,12%);
				display:block;
				line-height:30px;
				@include size(32px);
                position:relative;
                z-index:1;
				text-align:center;
                text-decoration:none;
				padding:0 0;
				overflow:hidden;
				font-size:.15em;
				outline:none;
                font-size:.88rem;
				font-family:$secondary-font-family;
				color:darken($light,16%);
				&:before{
					content:'';
					width:100%;
					height:0;
					top:0;
					left:0;
                    z-index:-1;
					position:absolute;
					display:block;
					background:darken($light,7%);
					@include transition(all .3s ease(in-out-quad));
				}
				&:hover{
                    color:$light;
					&:before{
						height:100%;
					}
				}
			}
			&.active{
				//margin:1.0em .9em;
				> a{
					//.scale(1.2);
					font-weight:100;
					border:0;
					background:$color-secondary;
                    color:$light;
                    &:before{
                        background:rgba($color-secondary,7%);
                    }
                    &:hover{
                        color:$light;
                    }
				}
			}
			&.next,
			&.prev{
			}
		}
	}
}

.partial-#{$root}-related{
    background-color:$light;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-image:url(/static/img/blog-related-bg.jpg);
    @include media-breakpoint-up(lg) {
        background-attachment:fixed;
    }
    h2{
        margin-bottom:2rem;
        text-align:center;

        color:$color-tertiary;
        @include bp(font-size,(  
            md:$h2-font-size*.92,
            lg:$h2-font-size,
            xl:$h2-font-size*1.08,   
            xxl:$h2-font-size*1.16   
        )); 

    }
    @extend %blog-item;
}


.content-#{$root}{
    padding-bottom:0;
	.col-date{
		padding-top:0em;
		@include media-breakpoint-up(lg) {
			text-align:right;
			padding-left:0;
		}
		span.date{
			color:$light;
            background: $color-secondary;
			line-height:1.5;
			font-family:$secondary-font-family;
			font-weight:400;
			font-size:1.1em;
			padding:.5em;
			> span.day{
				font-weight:100;
			}
			> span.month{
				font-weight:100;
			}
		}
	}
    .col-header{ 
        text-align:center;
		h1{
            margin-top:0;
            margin-bottom:1em;
            color:$color-tertiary;
            @include bp(font-size,(  
                xs:$h2-font-size*.7,
                md:$h2-font-size*.92,
                lg:$h2-font-size,
                xl:$h2-font-size*1.08,   
                xxl:$h2-font-size*1.16   
            )); 
        }
        .cover{
            margin-bottom:2rem;
            position:relative;
            z-index:1;
            img{
                @include img-fluid();
            }
            .more{
                position:absolute;
                z-index:2;
                color:$light;
                font-size:.8rem;
                background:rgba($color-tertiary,.829);
                padding:.6rem 1rem;
                bottom:0;
                width:100%;
                left:0;
                display:flex;
                .date{
                    //@include media-breakpoint-up(lg) {
                        width:50%;
                        text-align:left;
                    //}
                }
                .author{
                    //@include media-breakpoint-up(lg) {
                        width:50%;
                        text-align:right;
                    //}
                }
            }
        }
    }
	.col-content{
		font-size:1.0em;
        line-height:1.8;
        p.lead{
            font-weight:600;
            color:$gray-800;
            margin:1rem 0rem 2rem;
        }
        h4,h3,h2,h5,h6{
            margin-top:1.5em;
            margin-bottom:.8em;
            font-weight:600;
        }
        .content-img{
            margin:1.5rem 0;
            img{
                @include img-fluid();
            }
        }
		p{
			
		}
	}
    .col-gallery{
        padding-top:2rem;
        .col-item{
            padding-top:$grid-gutter-width;
            img{
                @include img-fluid();
            }
        }
    }
	hr{
		//margin-top:3em;
	}
    .#{root}-gallery{
		padding-top:1.5em;
		margin-top:1.5em;
		border-top:1px solid $light;
		margin-left:-.25em;
		margin-right:-.25em;
		position:relative;
		@include clearfix();
		> a{
			display:block;
			text-decoration:none;
			outline:none;
			margin-bottom:1em;
			overflow:hidden;
			position:relative;
			border:0;
			padding:.25em;
			@include media-breakpoint-up(md) {
				width:49%;
				float:left;
				&:nth-child(2n){
					//margin-left:2%;
				}
			}
			@include media-breakpoint-up(lg) {
				width:33.33%;
				float:left;
				&:nth-child(2n){
					//margin-left:auto;
				}
				&:nth-child(3n){
					//margin-left:2%;
				}
				&:nth-child(3n-1){
					//margin-left:2%;
				}
			}
			&:after{
				content:'';
				top:0;
				left:0;
				position:absolute;
				background:rgba(#fff,30%);
				display:block;
				width:100%;
				height:0%;
				z-index:9;
				@include transition(all .3s ease(in-out-quad));
			}
			&:hover{
				&:after{
					height:100%;
				}
			}
		}
	}
}

.#{$root}-related{
	margin-top:3em;
	border-top:1px solid darken($light,2%);
	padding-bottom:0;
	padding-top:3em;
	.item{
		position:relative;
		display:block;
		text-decoration:none;
		margin-bottom:2rem;
		> a.a-item{
			display:block;
			text-decoration:none;
			outline:none;
			.img{
				position:relative;
				overflow:hidden;
				display:block;
				img{
					@include transition(all 1.1s ease(in-out-quad));
				}
				span.date{
					position:absolute;
					bottom:1.5em;
					left:1.5em;
					color:$color-secondary;
					z-index:2;
					line-height:1.5;
					font-family:$secondary-font-family;
					font-weight:400;
					font-size:1.25em;
					padding:0em;
					text-align:center;
					background-color:rgba(#fff,92%);
					> span.day{
						font-weight:300;
					}
					> span.month{
					}
					> span.year{
						padding:.1em 1.2em;
						display:block;
						text-align:center;
						font-size:.8em;
						color:$light;
						background-color:$color-secondary;
					}
				}
			}
			.info{
				position:relative;
				padding-top:1.5em;
				display:block;
				h5,
				h4{
					color:$gray-900;
				}
				p{
					width:90%;
					color:lighten($gray-900,20%);
				}
			}
			.btn-more{
				display:inline-block;
				color:$light;
				float:right;
				z-index:1;
				position:relative;
				padding:0;
				> span.info{
					display:inline-block;
					overflow:hidden;
					padding:.65em 0em;
					margin-right:3em;
					color:rgba($gray-900,0%);
					font-weight:300;
					width:0px;
					@include transition(all .3s ease(in-out-quad));
				}
				> span.more{
					display:inline-block;
					overflow:hidden;
					$btn-size:42px;
					@include size($btn-size,$btn-size);
					z-index:2;
					color:$gray-900;
					text-decoration:none;
					outline:none;
					border:1px solid $color-secondary;
					position:relative;
					&:before{
						content:'';
						position:absolute;
						display:block;
						@include size(16px);
						@include rotate(-45);
						top:50%;
						left:50%; 
						margin-top:-8px;
						margin-left:-12px;
						border:1.3px solid darken($color-secondary,5%);
						border-top:0;
						border-left:0;
						@include transition(all .3s ease(in-out-quad));
					}
					&:after{ 
						content:'';
						height:100%;
						width:0;
						position:absolute;
						display:block;
						top:0%;
						left:0%; 
						z-index:-1;
						background-color:rgba($color-secondary,92%);
						@include transition(all .3s ease(in-out-quad));
					}
				}
			}
			&:hover{
				.img{
					img{
						//.scale(1.1);
					}
				}
				span.btn-more{
					span.info{
						margin-right:.6em;
						width:100px;
						color:$color-secondary;
					}
					span.more:after{
						width:100%;
					}
					span.more:before{
						border:1.3px solid $light;
						border-top:0;
						border-left:0;
					}
				}
			}
		}
	}
}